<template>
  <div id="app">
    <md-app md-mode="reveal">
      <md-app-toolbar class="md-primary">
        <span class="md-title">Beeper</span>
      </md-app-toolbar>
      <md-app-content>
        <HelloWorld msg="Welcome to Your Vue.js App"/>
      </md-app-content>
    </md-app>
    
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
</style>
