<template>
  <div class="hello">
    <div class="status_container">
      <span class="md-subheading">Status: </span> <span class="md-title">{{this.statusMessage}}</span>
      <span v-on:click="this.refreshConnection" id="refresh" class="material-icons">refresh</span>

      
    </div>
    <div v-if="!this.client.connected && canShowConnectionHelp">
      If you're having issues connecting to the server, make sure you are connected to the VPN.
    </div>

    <div class="actions_container">
      <md-card>
        
        <md-card-header>
          <div class="md-title">Send a beep to the device</div>
        </md-card-header>

        <md-card-content>
          Using the actions below, you can either send a beep to the device or stop a beep.
        </md-card-content>

        
        <md-card-actions>
          
          <md-button v-if="!this.isBeeping" v-on:click="this.alertDevice" class="md-raised md-primary">
            <span v-if="!this.isLoading">
              Activate Alert
            </span>
             <md-progress-spinner v-if="this.isLoading" :md-diameter="20" :md-stroke="2" md-mode="indeterminate"></md-progress-spinner>
            </md-button>
          <md-button v-if="this.isBeeping" v-on:click="this.stopAlert" class="md-raised md-accent">
            <span v-if="!this.isLoading">
              Stop Alert
            </span>
             <md-progress-spinner v-if="this.isLoading" :md-diameter="20" :md-stroke="2" md-mode="indeterminate"></md-progress-spinner>
          </md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import mqtt from 'mqtt'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      canShowConnectionHelp: false,
      isBeeping: false,
      isLoading: true,
      connection: {
        host: 'ksmcloud.com',
        port: '9001',
        endpoint: '/mqtt',
        clean: true, // Reserved session
        connectTimeout: 4000, // Time out
        reconnectPeriod: 4000, // Reconnection interval
        // Certification Information
        clientId: 'mqtt_web_' + this.generateGuid(),
      },
      statusResponseSubscription: {
        topic: 'topic/status_response',
        qos: 0
      },
      statusRequestPublish: {
        topic: 'topic/status_request',
        qos: 0,
        payload: ""
      },
      beepActivateRequestPublish: {
        topic: 'topic/beep_activate_request',
        qos: 0,
        payload: ""
      },
      beepDeActivateRequestPublish: {
        topic: 'topic/beep_deactivate_request',
        qos: 0,
        payload: ""
      },
      receiveNews: '',
      qosList: [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
      ],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
    }
  },
  mounted() {
    setTimeout(() => {
          this.canShowConnectionHelp = true;
        }, 5000);

    this.refreshConnection();
  },
  computed: {
    statusMessage: function(){
      if(this.client.connected){
        return "Connected";
      }

      return "Not Connected";
    }
  },
  methods: {
    refreshConnection(){
      this.destroyConnection();
      this.createConnection();
      this.doSubscribe(this.statusResponseSubscription);
      this.doPublish(this.statusRequestPublish);
    },
    alertDevice(){
      this.doPublish(this.beepActivateRequestPublish);
    },
    stopAlert(){
      this.doPublish(this.beepDeActivateRequestPublish);
    },
    // Create connection
    createConnection() {
      // Connect string, and specify the connection method used through protocol
      // ws unencrypted WebSocket connection
      // wss encrypted WebSocket connection
      // mqtt unencrypted TCP connection
      // mqtts encrypted TCP connection
      // wxs WeChat mini app connection
      // alis Alipay mini app connection
      const { host, port, endpoint, ...options } = this.connection
      const connectUrl = `ms://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, options)
      } catch (error) {
        console.log('mqtt.connect error', error)
      }
      this.client.on('connect', () => {
        this.canShowConnectionHelp = false;
        this.doPublish(this.statusRequestPublish);
        console.log('Connection succeeded!')
      })
      this.client.on('error', error => {
        setTimeout(() => {
          this.canShowConnectionHelp = true;
        }, 5000);
        console.log('Connection failed', error)
      })
      this.client.on('message', (topic, message) => {
        this.receiveNews = this.receiveNews.concat(message)
        var data = this.binArrayToString(message);
        if(topic == "topic/status_response") {
          if(data == "ON"){
            this.isBeeping = true;
          }
          else{
            this.isBeeping = false;
          }
          console.log("Status response obj", data);
          this.isLoading = false;
        }
        console.log(`Received message ${message} from topic ${topic}`)
      })
    },
    doSubscribe(subscription) {
      const { topic, qos } = subscription
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        this.subscribeSuccess = true
        console.log('Subscribe to topics res', res)
        })
    },
    doUnSubscribe() {
      const { topic } = this.subscription
      this.client.unsubscribe(topic, error => {
        if (error) {
          console.log('Unsubscribe error', error)
        }
      })
    },
    doPublish(publish) {
      const { topic, qos, payload } = publish
      if(topic == "topic/beep_activate_request" || topic == "topic/beep_deactivate_request" || topic == "topic/status_request"){
        this.isLoading = true;
      }

      this.client.publish(topic, payload, qos, error => {
        if (error) {
          console.log('Publish error', error)
        }
      })
    },
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end()
          this.client = {
            connected: false,
          }
          console.log('Successfully disconnected!')
        } catch (error) {
          console.log('Disconnect failed', error.toString())
        }
      }
    },
    binArrayToString: function(binArray) {
      var str = "";
      for (var i = 0; i < binArray.length; i++) {
          str += String.fromCharCode(parseInt(binArray[i]));
      } 
      return str
    },
    generateGuid: function() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.status_container{
  display: flex;
  align-items: center;
}

.status_container #refresh{
  margin-left: 10px;
}

.actions_container{
  margin-top: 20px;
}

</style>
<style>
circle{
  stroke: lightgray !important;
}
</style>